import './Assets/css/index.scss';
import './Assets/vendor/bootstrap.min.css'

import AbachiPresale from './Pages/app/index.js';

function App() {
  return (
    <div className="app">
      <AbachiPresale/>
    </div>
  );
}

export default App;
