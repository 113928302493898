import React from "react"
import Web3Utils from "web3-utils";

import browserWalletLogo from '../../Assets/images/browser-wallet.svg'
import connectWalletLogo from '../../Assets/images/connect-wallet.svg'
import linkWalletLogo from '../../Assets/images/link-wallet.svg'
import ledgerWalletLogo from '../../Assets/images/connect-ledger.svg'
import trezorLogo from '../../Assets/images/trezor.svg'
import errorIcon from '../../Assets/images/error-icon.svg'

import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";

import { Web3Provider } from "@ethersproject/providers";

import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";

// import {
//   URI_AVAILABLE,
//   UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
// } from "@web3-react/walletconnect-connector";

import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button
} from "reactstrap";

import {
  injected,
  // walletconnect,
  // walletlink,
  // ledger,
  // trezor
} from "./connectors";

import {
  ETHERSCANS,
  CHAIN_ID,
  CHAIN_INFO,
} from "../../Utils"

import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { CustomTitleToolTip } from "../CustomTitleToolTip";

import { useEagerConnect, useInactiveListener } from "./hooks";

const connectorsByName = {
  Injected: injected,
  // WalletConnect: walletconnect,
  // WalletLink: walletlink,
  // Ledger: ledger,
  // Trezor: trezor
};

async function switchChain(chain) {
  const params = {
      chainId: Web3Utils.toHex(chain.chainId),
      chainName: chain.name,
      nativeCurrency: {
        name: chain.nativeCurrency.name,
        symbol: chain.nativeCurrency.symbol,
        decimals: chain.nativeCurrency.decimals,
      },
      rpcUrls: chain.rpc,
      blockExplorerUrls: [ ((chain.explorers && chain.explorers.length > 0 && chain.explorers[0].url) ? chain.explorers[0].url : chain.infoURL) ]
    }
  await window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [params],
  })
}

function getErrorMessage(error, account) {
  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
  } else if (error instanceof UnsupportedChainIdError) {
    switchChain(CHAIN_INFO[CHAIN_ID])
    return "You're connected to an unsupported network. Please Open Metamask to automatically switch to the right chain.";
  } else if (
    error instanceof UserRejectedRequestErrorInjected 
    // || error instanceof UserRejectedRequestErrorWalletConnect
  ) {
    return "Please authorize this website to access your Ethereum account.";
  } else if(!account) {
      return "Please unlock your browser wallet";
  } else {
    console.error(error);
    return "An unknown error occurred. Check the console for more details.";
  }
}

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 5000;
  return library;
}

export function ConnectWalletRender({openConnectWalletModal, toggleConnectWalletModal, setConnected, address}) {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ConnectWallet
        openConnectWalletModal={openConnectWalletModal}
        toggleConnectWalletModal={toggleConnectWalletModal}
        setConnected={setConnected}
        address={address}
      />
    </Web3ReactProvider>
  );
}

const ConnectWallet = ({openConnectWalletModal, toggleConnectWalletModal, setConnected, address}) => {
    const context = useWeb3React();
    const {
      connector,
      library,
      chainId,
      account,
      activate,
      deactivate,
      active,
      error
    } = context;

    const [activatingConnector, setActivatingConnector] = React.useState();
    const [activatedConnector, setActivatedConnector] = React.useState();
    const [changingConnector, setChangingConnector] = React.useState();
    const [copyToClipBoard, setCopyToClipBoard] =  React.useState(false);
    const [previousAddress, setCurrentAddress] =  React.useState(false);

    React.useEffect(() => {
      if (activatingConnector && activatingConnector === connector) {
        setActivatingConnector(undefined);
        if(account) {
          toggleConnectWalletModal();
          setConnected(true, account, library, chainId, deactivate);
        }
      }
    }, [activatingConnector, connector]);

    React.useEffect(() => {
        if (chainId && !error) {
            toggleConnectWalletModal();
        }
    }, [chainId, error]);

    useInactiveListener(!!activatingConnector, setConnected);

    // React.useEffect(() => {
    //   const logURI = uri => {
    //     console.log("WalletConnect URI", uri);
    //   };
    //   walletconnect.on(URI_AVAILABLE, logURI);

    //   return () => {
    //     walletconnect.off(URI_AVAILABLE, logURI);
    //   };
    // }, []);

    const copyText = () => {
        setCopyToClipBoard(true);
        setTimeout(() => setCopyToClipBoard(false), 5000);
    }

    if(!address && account) {
      setConnected(true, account, library, chainId, deactivate);
    }

    if(previousAddress !== account && address) {
      setCurrentAddress(account)
      setConnected(true, account, library, chainId, deactivate);
    }

    return(
        <Web3ReactProvider getLibrary={getLibrary}>
          <Modal isOpen={openConnectWalletModal} className='connect-wallet-modal'>
              <ModalHeader className='text-brand-primary font-weight-medium py-2' toggle={toggleConnectWalletModal}>
                  Connect Your Wallet
              </ModalHeader>
              <ModalBody className='p-4'>
                  {!!error && (
                      <div className='error-message hand-pointer'><img src={errorIcon} className='mr-2' alt='errorIcon' />{getErrorMessage(error, account)}{chainId}</div>
                  )}
                  <div className='wallet-box hand-pointer'
                    onClick={() => {
                      const name = 'Injected'
                      const currentConnector = connectorsByName[name];
                      setActivatingConnector(currentConnector);
                      activate(currentConnector);
                    }}
                  >
                      <div className="d-flex flex-column align-items-center">
                          <img src={browserWalletLogo} alt='browserWalletLogo' />
                          <div className='text-neutral-3 mt-2'>
                          Connect to Browser Wallet e.g. MetaMask
                          </div>
                      </div>
                  </div>
                  <div className='d-flex my-3'>
                      <div className='wallet-box w-50 mr-2 hand-pointer disabled'
                        onClick={() => {
                          // const name = 'WalletConnect'
                          // const currentConnector = connectorsByName[name];
                          // setActivatingConnector(currentConnector);
                          // activate(currentConnector);
                        }}
                      >
                          <div className="d-flex flex-column align-items-center">
                              <img src={connectWalletLogo} alt='connectWalletLogo' />
                              <div className='text-center text-neutral-3 mt-2'>
                                  Connect to WalletConnect
                              </div>
                          </div>
                      </div>
                      <div className='wallet-box w-50 ml-1 hand-pointer disabled'
                        onClick={() => {
                          // const name = 'WalletLink'
                          // const currentConnector = connectorsByName[name];
                          // setActivatingConnector(currentConnector);
                          // activate(currentConnector);
                        }}
                      >
                          <div className="d-flex flex-column align-items-center ">
                              <img src={linkWalletLogo} className='w-40' alt='linkWalletLogo' />
                              <div className='text-center text-neutral-3 mt-2'>
                                  Connect to WalletLink
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className='d-flex mb-3'>
                      <div className='wallet-box w-50 mr-2 hand-pointer disabled'
                        onClick={() => {
                          // const name = 'Ledger'
                          // const currentConnector = connectorsByName[name];
                          // setActivatingConnector(currentConnector);
                          // activate(currentConnector);
                        }}
                      >
                          <div className="d-flex flex-column align-items-center">
                              <img src={ledgerWalletLogo} className='w-60' alt='ledgerWalletLogo' />
                              <div className='text-center text-neutral-3 mt-2'>
                                  Connect to Ledger
                              </div>
                          </div>
                      </div>
                      <div className='wallet-box w-50 ml-1 hand-pointer disabled'
                        onClick={() => {
                          // const name = 'Trezor'
                          // const currentConnector = connectorsByName[name];
                          // setActivatingConnector(currentConnector);
                          // activate(currentConnector);
                        }}
                      >
                          <div className='d-flex flex-column align-items-center'>
                              <img src={trezorLogo} className='w-60' alt={'trezorLogo'} />
                              <div className='text-center text-neutral-3 mt-2'>
                                  Connect to Trezor
                              </div>
                          </div>
                      </div>
                  </div>
                  {/* {connector === walletconnect && account && (
                    <Button
                      color=""
                      className='btn-danger btn-shadow btn-primary beveled px-4 py-1 font-14'
                      onClick={() => {
                        connector.close();
                      }}
                    >
                      Kill WalletConnect Session
                    </Button>)
                  } */}
              </ModalBody>
              { address &&
              <ModalFooter className='flex w-100 justify-content-between py-1'>
                  <Button
                      color=""
                      onClick={() => {
                        deactivate();
                        setConnected(false, '');
                      }}
                      className='btn-danger btn-shadow btn-primary beveled px-4 py-1 font-14'
                  >
                      Disconnect
                  </Button>
                  <Button
                      color=""
                      onClick={toggleConnectWalletModal}
                      className='btn-shadow btn-primary beveled px-4 py-1 font-14'
                  >
                      Close
                  </Button>
              </ModalFooter>
              }
          </Modal>
        </Web3ReactProvider>
    )
}
