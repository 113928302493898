import React, { Component } from "react";
import {
    Button,
    Input,
    ModalHeader, ModalBody, ModalFooter, Modal,
} from "reactstrap";
import { Blockie } from "../../Components/Blockie";
import { CustomTitleToolTip } from "../../Components/CustomTitleToolTip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConnectWalletRender } from "../../Components/ConnectWallet";
import Web3Utils from "web3-utils";
import { ethers } from "ethers";

import {
    ellipseAddress,
    erc20ABI,
    alphaAbachiAddress,
    abachiVestingABI,
    sabiABI,
    sabiAddress,
    presaleAddress,
    pabiAddress,
    CHAIN_INFO,
    CHAIN_ID,
    vestingAddress,
} from "../../Utils/index";
import githubLogo from "../../Assets/images/github_filled.svg"
import twitterLogo from "../../Assets/images/twitter_circle_filled.svg"
import carboLogo from "../../Assets/images/carbon_logo_discord.svg"
import mailLogo from "../../Assets/images/mail_circle.svg"
import approveIcon from "../../Assets/images/approve-icon.svg"
import depositIcon from "../../Assets/images/deposite-icon.svg"
import eligibleIcon from "../../Assets/images/eligible-icon.svg"
import notEligibleIcon from "../../Assets/images/not-eligible-icon.svg"
import daiLogo from "../../Assets/images/dai-logo.svg"
import errorIcon from '../../Assets/images/error-icon.svg'
import arrowRightIcon from "../../Assets/images/arrow-right.svg"
import arrowDownIcon from "../../Assets/images/arrow.svg"
import copyIcon from "../../Assets/images/copy-icon.svg";
import externalLinkIcon from "../../Assets/images/external-link.svg";
import plusCirclegradIcon from "../../Assets/images/plus-circle-gradient.svg";
import plusCircleIcon from "../../Assets/images/plus-circle.svg";
import lockIcon from "../../Assets/images/lock-icon.svg";
import sellIcon from "../../Assets/images/sell-icon.svg";
import paladinLogo from "../../Assets/images/logo-paladin.svg";
import rugdocLogo from "../../Assets/images/logo-rugDoc.svg";
import { Header } from "../../Components/Header";

import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

export default class AbachiPresale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chainId: CHAIN_ID,
            connected: false,
            latestBlock: 13213231,
            address: "",
            error: "",
            openConnectWalletModal: false,
            latestNotes: [],
            circulating: 0,
            total: 0,
            web3: null,
            daiBalance: 0,
            pabiBalance: 0,
            pabiBalanceSelected: 0,
            gabiVested: 0,
            currentIndex: 0,
            aabiBalance: 0,
            approvedBalance: 0,
            remainingAllocation: 0,
            whitelistStatus: false,
            needsApproval: false,
            daiToDeposit: 1000,
            receipt: null,
            tx: null,
            hasSigned: false,
            toggle: false,
            debounce: false,
            openAccountInfoModal: false,
            deactivate: () => { },
            copyToClipBoard: false,
            sellLink: "https://app.uniswap.org/#/swap?inputCurrency=0x2e665e9134c492fcca4b2a5f308a1f62ae7747a1&outputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063&chain=polygon"
        };
    }

    copyText = () => {
        this.setState({ copyToClipBoard: true });
        setTimeout(() => this.setState({ copyToClipBoard: false }), 5000);
    };

    toggleConnectWalletModal = () => {
        this.setState({
            openConnectWalletModal: !this.state.openConnectWalletModal,
        });
    };

    setConnected = async (connected, account, web3, chainId, deactivate) => {
        console.log(connected)
        console.log("connected");
        if (this.state.debounce) return;
        this.setState({
            debounce: true,
        });
        setTimeout(() => {
            this.setState({
                debounce: false,
            });
        }, 1000);
        await this.setState({
            connected: connected,
            address: account,
            web3: web3,
            chainId: chainId,
            error: "",
            deactivate: deactivate,
        });
        if (!connected) {
            await this.setState({
                daiBalance: 0,
                aabiBalance: 0,
                approvedBalance: 0,
                remainingAllocation: 0,
                whitelistStatus: false,
                needsApproval: false,
                daiToDeposit: 1000,
            });
        }
        this.update();
    };

    update = async () => {
        console.log("in update")
        if (!this.state.address) {
            return;
        }
        await this.getpABIBalance();
        await this.getpABIIndex();
        await this.getApprovalBalance();
        await this.getVestedgABI();
    };

    approve = async () => {
        try {
            const provider = this.state.web3;
            const signer = provider && provider.getSigner();
            const contract = new ethers.Contract(pabiAddress, erc20ABI, signer);
            const tx = await contract.approve(
                vestingAddress,
                ethers.utils.parseEther(this.state.pabiBalance.toString())
            );
            this.setState({
                tx,
                receipt: null,
                error: false,
            });
            const receipt = await tx.wait();
            this.setState({
                receipt,
            });
            this.update();
        } catch (error) {
            this.setErrorMessage(error.message);
        }
    };

    vest = async () => {
        try {
            const provider = this.state.web3;
            const signer = provider && provider.getSigner();
            const contract = new ethers.Contract(vestingAddress, abachiVestingABI, signer);
            const tx = await contract.vest(
                ethers.utils.parseEther(this.state.pabiBalanceSelected.toString())
            );
            this.setState({
                tx,
                receipt: null,
                error: false,
            });
            const receipt = await tx.wait();
            this.setState({
                receipt,
            });
            this.update();
        } catch (error) {
            this.setErrorMessage(error.message);
        }
    };

    setErrorMessage = async (error) => {
        this.setState({
            error: error,
        });
    };

    getpABIBalance = async () => {
        if (!this.state.connected) return;
        return new Promise(async (resolve, reject) => {
            try {
                const provider = this.state.web3;
                const signer = provider && provider.getSigner();
                const contract = new ethers.Contract(pabiAddress, erc20ABI, signer);
                const pabiBalance = await contract.balanceOf(this.state.address);
                console.log(Web3Utils.fromWei(pabiBalance.toString(), "ether"))
                await this.setState({
                    pabiBalance: Web3Utils.fromWei(pabiBalance.toString(), "ether"),
                    pabiBalanceSelected: Web3Utils.fromWei(pabiBalance.toString(), "ether"),
                });
                resolve(Web3Utils.fromWei(pabiBalance.toString(), "ether"));
            } catch (error) {
                this.setErrorMessage(error.message);
            }
        });
    };

    getpABIIndex = async () => {
        if (!this.state.connected) return;
        return new Promise(async (resolve, reject) => {
            try {
                const provider = this.state.web3;
                const signer = provider && provider.getSigner();
                const contract = new ethers.Contract(sabiAddress, sabiABI, signer);
                const sabiIndex = await contract.index();
                console.log(sabiIndex);
                console.log(Web3Utils.fromWei(sabiIndex.toString(), "GWei"))
                await this.setState({
                    currentIndex: Web3Utils.fromWei(sabiIndex.toString(), "GWei"),
                });
                this.setState({ gabiBalance: this.state.pabiBalance / this.state.currentIndex });

                resolve(Web3Utils.fromWei(sabiIndex.toString(), "GWei"));
            } catch (error) {
                this.setErrorMessage(error.message);
            }
        });
    };

    getVestedgABI = async () => {
        if (!this.state.connected) return;
        return new Promise(async (resolve, reject) => {
            try {
                const provider = this.state.web3;
                const signer = provider && provider.getSigner();
                const contract = new ethers.Contract(vestingAddress, abachiVestingABI, signer);
                const gABIBalance = await contract.balanceOf(this.state.address);
                const gABIVested = Web3Utils.fromWei(gABIBalance.toString(), "ether");
                console.log("vested gABI", gABIVested);                
                this.setState({ gabiVested: parseFloat(gABIVested).toFixed(4) });
                resolve(gABIVested);
            } catch (error) {
                this.setErrorMessage(error.message);
            }
        });
    };

    getApprovalBalance = () => {
        if (!this.state.connected) return;
        return new Promise(async (resolve, reject) => {
            const provider = this.state.web3;
            const signer = provider && provider.getSigner();
            const contract = new ethers.Contract(pabiAddress, erc20ABI, signer);
            const actualBalance = await contract.allowance(
                this.state.address,
                vestingAddress
            );
            const approvedBalance = Web3Utils.fromWei(
                actualBalance.toString(),
                "ether"
            );
            const needsApproval =
                parseInt(this.state.pabiBalance) > parseInt(approvedBalance)
                    ? true
                    : false;
            console.log(needsApproval, "needs approval")
            await this.setState({
                approvedBalance,
                needsApproval,
            });
            resolve({ approvedBalance, needsApproval });
        });
    };

    handleChange = (event) => {
        this.setState({
            error: false,
            daiToDeposit: event.target.value.replace(/^0+/, ""),
        });
    };

    toggleAccountInfoModal = () => {
        this.setState({
            openAccountInfoModal: !this.state.openAccountInfoModal,
        });
    };

    render() {
        const {
            address,
            connected,
            whitelistStatus,
            openAccountInfoModal,
            account,
        } = this.state;
        return (
            <div className="main-page">
                <ConnectWalletRender
                    setWeb3={this.setWeb3}
                    openConnectWalletModal={this.state.openConnectWalletModal}
                    toggleConnectWalletModal={this.toggleConnectWalletModal}
                    setConnected={this.setConnected}
                    address={address}
                />
                <Modal isOpen={openAccountInfoModal} className="connect-wallet-modal">
                    <ModalHeader className="text-brand-primary font-weight-medium py-2">
                        Account
                    </ModalHeader>
                    <ModalBody className="p-4 text-center">
                        <div className="text-brand-primary font-weight-medium d-flex justify-content-center align-items-center">
                            Connected with MetaMask
                        </div>
                        <div className="my-5 text-neutral-7 d-flex justify-content-center align-items-center">
                            <CustomTitleToolTip
                                tip={
                                    !this.state.copyToClipBoard ? "Copy to Clipboard" : "Copied!"
                                }
                                header={
                                    <CopyToClipboard text={address} onCopy={this.copyText}>
                                        <div className="hand-pointer">
                                            {address}
                                            <img src={copyIcon} className="ml-2" />
                                        </div>
                                    </CopyToClipboard>
                                }
                            />
                        </div>
                        <a
                            href={`${CHAIN_INFO[CHAIN_ID].explorers[0].url}/address/${address}`}
                            target="_blank"
                            rel="noopener"
                            className="cleanLink text-decoration-none"
                            aria-label="Block Explorer"
                        >
                            <div className="text-brand-primary d-flex justify-content-center align-items-center mb-3">
                                View on explorer
                                <img
                                    src={externalLinkIcon}
                                    className="ml-2"
                                    alt="externalLinkIcon"
                                />
                            </div>
                        </a>
                    </ModalBody>
                    <ModalFooter className="flex w-100 justify-content-between py-1">
                        <Button
                            color=""
                            onClick={() => {
                                this.setConnected(false, "");
                                this.state.deactivate();
                                this.toggleAccountInfoModal();
                            }}
                            className="btn-danger btn-shadow btn-primary beveled px-4 py-1 font-14"
                        >
                            Disconnect
                        </Button>
                        <Button
                            color=""
                            onClick={this.toggleAccountInfoModal}
                            className="btn-shadow btn-primary beveled px-4 py-1 font-14"
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
                <div className="top-section">
                    <div id="stars" />
                    <div id="stars2" />
                    <div id="stars3" />
                    <div className="container">
                        <Header
                            address={address}
                            connected={connected}
                            whitelistStatus={whitelistStatus}
                            ellipseAddress={ellipseAddress}
                            toggleConnectWalletModal={this.toggleConnectWalletModal}
                            toggleAccountInfoModal={this.toggleAccountInfoModal}
                        />


                        <div className="hero-section">
                            <div className="header">Convert PABI to GABI</div>
                            {!this.state.address && (
                                <div className="text-center sub-text my-4 mx-auto">
                                    <div>
                                        Connect your wallet to convert pABI to gABI
                                        <div>
                                            <div className="mt-4">
                                                {connected && address ? (
                                                    <CustomTitleToolTip
                                                        className="w-50"
                                                        tip={address}
                                                        header={
                                                            <div
                                                                className="sm-hidden block d-flex justify-content-between align-items-end invoice-header align-items-center mb-2"
                                                                onClick={this.toggleAccountInfoModal}
                                                            >
                                                                <Blockie address={address} />
                                                                <div className="ml-2">
                                                                    {ellipseAddress(address)}
                                                                </div>
                                                            </div>
                                                        }
                                                    />
                                                ) : (
                                                    !whitelistStatus && (
                                                        <Button
                                                            id="caret"
                                                            color=""
                                                            className="connect-wallet-btn btn-primary sm:px-0 px-5"
                                                            onClick={this.toggleConnectWalletModal}
                                                        >
                                                            <img src={plusCircleIcon} className="mr-2" />
                                                            Connect Wallet
                                                        </Button>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {this.state.address && (
                                <div className="hero-section">
                                    <div className="d-flex w-100 px-5">
                                        <div className="mr-auto p-2 tx-text">Vested GABI</div>
                                        <div className="p-2 sub-text">{this.state.gabiVested}</div>
                                    </div>
                                    <div className="box">
                                        <div className="p25">
                                            <div className="small-label text-start pb-3 title-text">
                                                Your PABI Balance
                                            </div>
                                            <div className="pb-3">
                                                <input
                                                    className="text-box py-2 px-3"
                                                    placeholder="PABI Balance"
                                                    defaultValue={this.state.pabiBalance}
                                                    type="number"
                                                    max={this.state.pabiBalance}
                                                    value={this.state.pabiBalanceSelected}
                                                    min={0}
                                                    step={1}
                                                    onChange={(e) => {
                                                        if (parseFloat(e.target.value) > parseFloat(this.state.pabiBalance)) {
                                                            this.state.pabiBalanceSelected = this.state.pabiBalance;
                                                        } else {
                                                            this.state.pabiBalanceSelected = e.target.value;
                                                        }
                                                        this.setState({ gabiBalance: e.target.value / this.state.currentIndex });
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <img
                                                    src={arrowDownIcon}
                                                    className="my-2"
                                                    alt="approveIcon"
                                                />
                                            </div>
                                            <div className="small-label text-start pb-3 title-text">
                                                GABI
                                            </div>
                                            <div className="pb-3 mb-3">
                                                <input
                                                    className="text-box py-2 px-3"
                                                    placeholder="GABI Balance"
                                                    value={this.state.gabiBalance}
                                                    type="number"
                                                    readOnly
                                                />
                                            </div>
                                            <div className="d-none d-sm-block">
                                                <div className="d-flex justify-content-between ">
                                                    {
                                                        this.state.needsApproval && (
                                                            <Button
                                                                color=""
                                                                className="btn-primary buysell-btn"
                                                                onClick={() => {
                                                                    this.approve();
                                                                }}>
                                                                <img
                                                                    src={approveIcon}
                                                                    className="mr-2"
                                                                    alt="approveIcon"
                                                                />
                                                                Approve
                                                            </Button>
                                                        )
                                                    }
                                                    {
                                                        !this.state.needsApproval && (
                                                            <Button color="" className="btn-primary buysell-btn" onClick={() => {
                                                                this.vest();
                                                            }}>
                                                                <img
                                                                    src={lockIcon}
                                                                    className="mr-2"
                                                                    alt="approveIcon"
                                                                />
                                                                Lock & Stake
                                                            </Button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-block d-sm-none">
                                        <div className="d-flex justify-content-between ">
                                        {this.state.needsApproval && (
                                            <Button color="" className="btn-primary buysell-btn" onClick={() => {
                                                                this.approve();
                                                            }}>
                                                <img src={approveIcon} className="mr-2" alt="approveIcon" />
                                                Approve
                                            </Button>
                                        )}                                        
                                        {!this.state.needsApproval && (
                                            <Button color="" className="btn-primary buysell-btn" onClick={() => {
                                                                this.vest();
                                                            }}>
                                                <img src={lockIcon} className="mr-2" alt="approveIcon" />
                                                Lock & Stake
                                            </Button>
                                        )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="font-12 mt-1">
                                {this.state.tx ? (
                                    <div className="message text-neutral-1 d-flex align-items-center justify-content-center">
                                        <p className="tx-wrap">TX Hash (pending): </p>
                                        <a
                                            target="_blank"
                                            className="tx-text"
                                            rel="noreferrer"
                                            href={`${CHAIN_INFO[CHAIN_ID].explorers[0].url}/tx/${this.state.tx.hash}`}
                                        >{`${this.state.tx.hash}`}</a>
                                    </div>
                                ) : null}
                                {this.state.receipt ? (
                                    <div className="message text-neutral-1 mt-2 d-flex align-items-center justify-content-center">
                                        <p className="tx-wrap">TX Receipt (confirmed): </p>
                                        <a
                                            target="_blank"
                                            className="tx-text"
                                            rel="noreferrer"
                                            href={`${CHAIN_INFO[CHAIN_ID].explorers[0].url}/tx/${this.state.receipt.transactionHash}`}
                                        >{`${this.state.receipt.transactionHash}`}</a>
                                    </div>
                                ) : null}
                            </div>
                            {this.state.error ? (
                                <div className="error-message2 mw-100 hand-pointer">
                                    <img src={errorIcon} className="mr-2" alt="errorIcon" />{" "}
                                    {this.state.error}{" "}
                                </div>
                            ) : null}

                        
                        
                        </div>
                        {(connected && address) ? "" : <div className="mt-3"> <br /></div>}
                        

                        <footer className={`hero-footer d-flex ${(connected && address) ?  "mt-4" : "mt-5"}`}>
                            <a
                                href="https://github.com/abachi-io"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={githubLogo}
                                    className="cursor-pointer"
                                    alt="githubLogo"
                                />
                            </a>
                            <a
                                href="https://twitter.com/Abachi_io"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={twitterLogo}
                                    className="cursor-pointer"
                                    alt="twitterLogo"
                                />
                            </a>
                            <a
                                href="https://discord.gg/abachi"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={carboLogo}
                                    className="cursor-pointer"
                                    alt="carboLogo"
                                />
                            </a>
                            <a href="mailto:info@abachi.io" rel="noreferrer">
                                <img src={mailLogo} className="cursor-pointer" alt="" />
                            </a>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}