import ERC20ABI from './ERC20.abi.json'
import AbachiVestingABI from './AbachiVesting.abi.json'
import SABI from './SABI.abi.json'

export const erc20ABI = ERC20ABI
export const abachiVestingABI = AbachiVestingABI
export const sabiABI = SABI

export const alphaAbachiAddress = '0x835BA011Ec70e695879185dF3d46939DBfBeF7E5'
export const sabiAddress = '0x925a785a347f4a03529b06C50fa1b9a10808CAb5'
export const pabiAddress = '0x2E665e9134C492FCcA4b2a5f308A1f62Ae7747A1'
export const presaleAddress = '0x62Fb6F77cebc8500448FbFB8D4F875f01d01531d'
export const vestingAddress = '0xb335752608eE4FCDCeD2C1Dc565b55c910fec623'

export function ellipseAddress(address = "", width = 6) {
  return `${address.slice(0, width)}...${address.slice(-width)}`;
}

export const POLLING_INTERVAL = 5000;

export const CHAIN_ID = 137;

export const CHAIN_INFO = {
   80001:{
      "name":"Polygon Testnet Mumbai",
      "chain":"Polygon",
      "network":"testnet",
      "rpc":[
         "https://matic-mumbai.chainstacklabs.com",
         "https://rpc-mumbai.maticvigil.com",
         "https://matic-testnet-archive-rpc.bwarelabs.com"
      ],
      "faucets":[
         "https://faucet.polygon.technology"
      ],
      "nativeCurrency":{
         "name":"MATIC",
         "symbol":"MATIC",
         "decimals":18
      },
      "infoURL":"https://polygon.technology",
      "shortName":"maticmum",
      "chainId":80001,
      "networkId":80001,
      "explorers":[
         {
            "name":"polygonscan",
            "url":"https://mumbai.polygonscan.com",
            "standard":"EIP3091"
         }
      ]
   },
   137:{
      "name":"Polygon Mainnet",
      "chain":"Polygon",
      "network":"mainnet",
      "rpc":[
         "https://polygon-rpc.com/",
         "https://rpc-mainnet.matic.network",
         "https://matic-mainnet.chainstacklabs.com",
         "https://rpc-mainnet.maticvigil.com",
         "https://rpc-mainnet.matic.quiknode.pro",
         "https://matic-mainnet-full-rpc.bwarelabs.com"
      ],
      "faucets":[

      ],
      "nativeCurrency":{
         "name":"MATIC",
         "symbol":"MATIC",
         "decimals":18
      },
      "infoURL":"https://polygon.technology",
      "shortName":"MATIC",
      "chainId":137,
      "networkId":137,
      "slip44":966,
      "explorers":[
         {
            "name":"polygonscan",
            "url":"https://polygonscan.com",
            "standard":"EIP3091"
         }
      ]
   }
}

export const RPC_URLS = {
  80001: 'https://polygon-mumbai.g.alchemy.com/v2/sAMvjMQb9w57_kCGnwWfnwupEv-u6Mah',
  137: 'https://polygon-mainnet.g.alchemy.com/v2/h2AuPW4DbJykjbZdYTozxcT-eM6y0WIj',
};

export const ETHERSCANS = {
    '137': 'https://polygonscan.com',
    '80001': 'https://mumbai.polygonscan.com',
}
