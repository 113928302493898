import { InjectedConnector } from "@web3-react/injected-connector";
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
// import { LedgerConnector } from "@web3-react/ledger-connector";
// import { TrezorConnector } from "@web3-react/trezor-connector";
// import { WalletLinkConnector } from "@web3-react/walletlink-connector";

import {
  RPC_URLS,
  POLLING_INTERVAL,
  CHAIN_ID,
} from "../../Utils"

export const injected = new InjectedConnector({
  supportedChainIds: [CHAIN_ID]
});

// export const walletconnect = new WalletConnectConnector({
//   rpc: { chainId: RPC_URLS[CHAIN_ID] },
//   infura: '4c30751ecd424c66aa6577ae9dcb8bf5',
//   bridge: "https://bridge.walletconnect.org",
//   qrcode: true,
//   pollingInterval: POLLING_INTERVAL
// });

// export const walletlink = new WalletLinkConnector({
//   url: RPC_URLS[CHAIN_ID],
//   appName: "web3-react example"
// });

// export const ledger = new LedgerConnector({
//   chainId: CHAIN_ID,
//   url: RPC_URLS[CHAIN_ID],
//   pollingInterval: POLLING_INTERVAL
// });

// export const trezor = new TrezorConnector({
//   chainId: CHAIN_ID,
//   url: RPC_URLS[CHAIN_ID],
//   pollingInterval: POLLING_INTERVAL,
//   manifestEmail: "dummy@abc.xyz",
//   manifestAppUrl: "https://8rg3h.csb.app/"
// });
