import React  from 'react';
import ReactTooltip from 'react-tooltip'

export const CustomTitleToolTip = ({tip, header}) => {
    return (
        <div>
          <div >
            <span className="no-border" data-tip={tip}>{header}</span>
            <ReactTooltip/>
          </div>
        </div>
    )

}
