import React  from "react"
import * as blockies from "blockies-ts";

export const Blockie = (props) => {
    const address = props.address ? props.address.toLowerCase() : '0x0000000000000000000000000000000000000000'
    const imgUrl = blockies.create({ seed: address }).toDataURL();
    return(
        <div className="blockie">
            <img src={imgUrl} alt={address} />
        </div>
    )
}

